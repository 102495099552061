import { Component } from 'react';
import { Columns, Container } from 'react-bulma-components';
import vorteile from '../PNG/Vorteile.png';

export class Vorteile extends Component {
  render() {
    return (
      <Container className="Vorteile">
        <Columns vCentered={ true } className="mx-0">
          <Columns.Column>
            <figure className="image">
              <img src={ vorteile } alt="Vorteile"/>
            </figure>
          </Columns.Column>

          <Columns.Column>
            <h2 className="has-text-danger is-size-2 has-text-weight-semibold mt-6 mb-3 has-text-centered-touch">
              Deine Vorteile
            </h2>

            <ul className="fa-ul is-size-4">
              <li>
                <span className="fa-li"><i className="fas fa-plus has-text-danger mr-5"></i></span>
                Kontakte zu namhaften Arbeitgebern und Stellenangeboten
              </li>
              <li>
                <span className="fa-li"><i className="fas fa-plus has-text-danger mr-5"></i></span>
                Berücksichtigung der individuellen Lebensumstände
              </li>
              <li>
                <span className="fa-li"><i className="fas fa-plus has-text-danger mr-5"></i></span>
                bei der Dienstplangestaltung
              </li>
              <li>
                <span className="fa-li"><i className="fas fa-plus has-text-danger mr-5"></i></span>
                Unbefristeter Arbeitsvertrag und eine übertarifliche Vergütung
              </li>
              <li>
                <span className="fa-li"><i className="fas fa-plus has-text-danger mr-5"></i></span>
                Urlaubs- und Weihnachtsgeld sowie attraktive Zulagen
              </li>
              <li>
                <span className="fa-li"><i className="fas fa-plus has-text-danger mr-5"></i></span>
                Betriebliche Altersvorsorge und Kinderbetreuungszuschuss
              </li>
              <li>
                <span className="fa-li"><i className="fas fa-plus has-text-danger mr-5"></i></span>
                Feste Ansprechpartner mit jahrelanger Branchenerfahrung
              </li>
              <li>
                <span className="fa-li"><i className="fas fa-plus has-text-danger mr-5"></i></span>
                Fachspezifische Fortbildungen und Schulungen
              </li>
            </ul>
          </Columns.Column>
        </Columns>
      </Container>
    )
  }
}
