import {ChangeEvent, Component} from "react";
import {Button, Columns, Container, Form} from "react-bulma-components";
import {WizardState} from "../App";
import {InputField} from "./InputField";

export class Step3 extends Component<{ state: WizardState }> {

  constructor(props: { state: WizardState }) {
    super(props);
    this.state = { ...props };
    this.nextStep = this.nextStep.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e: ChangeEvent<HTMLInputElement>) {
    this.props.state.form[e.target.name] = e.target.value;
    this.setState({form: this.props.state.form});
  }

  nextStep() {
    this.props.state.stepWizard.nextStep();
  }

  render() {
    return (
      <div className="header">
        <header>
          <h1 className="my-10">In welcher Stadt möchtest du arbeiten?</h1>
        </header>

        <Form.Control>
          <Container className="py-6 pb-10">
            <Columns centered={true} breakpoint="desktop" className="mx-0">
              <Columns.Column size={6} className={"has-text-centered"}>
                <InputField name={"stadt-1"}
                            placeholder={"z.B. Hannover"}
                            className={"has-text-centered"}
                            onChange={this.onChange}/>
              </Columns.Column>
            </Columns>

            <Columns centered={true} breakpoint="desktop" className="mx-0">
              <Columns.Column size={6} className={"has-text-centered"}>
                <InputField  name={"stadt-2"}
                             placeholder={"z.B. Köln"}
                             className={"has-text-centered"}
                             onChange={this.onChange}/>
              </Columns.Column>
            </Columns>

            <Columns centered={true} breakpoint="desktop" className="mx-0">
              <Columns.Column size={6} className={"has-text-centered"}>
                <InputField  name={"stadt-3"}
                             placeholder={"z.B. Bremen"}
                             className={"has-text-centered"}
                             onChange={this.onChange}/>
              </Columns.Column>
            </Columns>

            <Columns centered={true} breakpoint="desktop" className="mx-0">
              <Columns.Column size={6} className={"has-text-centered"}>
                <Button color="primary" rounded={true} onClick={this.nextStep} fullwidth={true} size={"large"}>
                  Weiter
                </Button>
              </Columns.Column>
            </Columns>
          </Container>
        </Form.Control>
      </div>
    );
  }

}
