import {Component} from "react";
import {Columns} from "react-bulma-components";
import {StepWizardChildProps} from "react-step-wizard";
import {WizardState} from "../App";

export class ClickableIcon extends Component<{ wizard: StepWizardChildProps, state: WizardState, value: string, field: string, size?: any | undefined, desktop?: any | undefined, additionalIconText?: string, icon: string, title: string }> {
  constructor(props: { wizard: StepWizardChildProps, state: WizardState, formData: any, value: string, field: string, size?: any | undefined, additionalIconText?: string, icon: string, title: string }) {
    super(props);
    this.state = {...props};
    this.nextStep = this.nextStep.bind(this);
  }

  static getDerivedStateFromProps(props: { wizard: StepWizardChildProps, value: string, field: string, size: any | undefined, icon: string, title: string }) {
    if (props.wizard) {
      return {wizard: props.wizard};
    }

    return null;
  }

  nextStep() {
    const obj: any = {};
    obj[this.props.field] = this.props.value;

    this.props.state.form[this.props.field] = this.props.value;
    this.setState({form: this.props.state.form});

    this.props.wizard.nextStep();
  }

  render() {
    return (
      <Columns.Column className="has-text-centered is-clickable" mobile={{size: 12}} desktop={{size: 6}}
                      onClick={this.nextStep}>
        <p className="button is-primary is-block">
          <span className="icon">
            <i className={`fas ${this.props.icon}`}></i>
          </span>
          <span>{this.props.title}</span>
        </p>
      </Columns.Column>
    );
  }

}
