import { Component } from 'react';
import { Columns } from 'react-bulma-components';
import FooterButton from '../SVG/FooterButton.svg';

export class ApplyNow extends Component {
  render() {
    return (
      <div>
        <div className="footer mt-10">
          <Columns centered={ true } className="footer-start mx-0">
            <Columns.Column className="has-text-centered pt-10">
              <h1 className="mt-10 is-size-2-touch">Worauf wartest du also noch?</h1>
              <h2 className="my-6 is-size-2-touch">Bewirb dich jetzt!</h2>
              <a target="_blank" rel="noreferrer" href="https://akut-med.de/jobs">
                <img src={ FooterButton } alt="" width={ '200px' }/>
              </a>
            </Columns.Column>
          </Columns>
        </div>
      </div>
    )
  }
}
