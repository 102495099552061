import { Component } from 'react';
import { WizardState } from '../App';
import { Icons } from './Icons';

export class Header extends Component<{ state: WizardState }> {
  constructor(props: { state: WizardState }) {
    super(props);

    this.state = { ...props };
  }

  static getDerivedStateFromProps(props: WizardState) {
    if ( props.stepWizard ) {
      return { stepWizard: props.stepWizard };
    }

    return null;
  }

  render() {
    return (
      <div className="header">
        <header>
          <h1 className="mt-3">Wir haben den <span className="has-text-danger">besten Job</span> für Dich!</h1>
          <h2 className="my-3">In welchem Bereich suchst Du?</h2>
        </header>

        <Icons state={ this.props.state }/>

      </div>
    )
  }

}
