import { Component } from 'react';
import { Columns, Container } from 'react-bulma-components';
import { ReactComponent as ArrowDiagonal } from '../SVG/ArrowDiagonal.svg';
import { ReactComponent as ArrowDown } from '../SVG/ArrowDown.svg';
import { ReactComponent as ArrowLeft } from '../SVG/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../SVG/ArrowRight.svg';
import Bubbles from '../SVG/Bubbles.svg';
import Einstellung from '../SVG/Einstellung.svg';

export class Funktion extends Component {
  render() {
    return (
      <Container className="Funktion">
        <Columns vCentered={ true } textAlign={ 'center' } className="mx-0">
          <Columns.Column>
            <img src={ Bubbles } alt=""/>
          </Columns.Column>

          <Columns.Column className={ 'first-column' }>
            <h2 className="has-text-danger is-size-3-touch">Und so funktioniert es...</h2>
            <ArrowDown height="400px" className="pt-5 is-hidden-touch"/>
            <i className="is-hidden-desktop fa fa-chevron-down fa-2x my-6 has-text-danger"></i>
          </Columns.Column>
        </Columns>

        <Columns vCentered={ true } textAlign={ 'center' } className={ 'is-reversed-touch mx-0' }>
          <Columns.Column>
            <i className="fas fa-paper-plane fa-10x has-text-danger is-hidden-touch"></i>
            <i className="fas fa-paper-plane fa-3x has-text-danger is-hidden-desktop mb-5"></i>
            <h2 className="is-size-3-touch">Wir melden uns bei dir</h2>
          </Columns.Column>

          <Columns.Column>
            <ArrowLeft className="is-hidden-touch"/>
            <i className="is-hidden-desktop fa fa-chevron-down fa-2x my-6 has-text-danger"></i>
          </Columns.Column>

          <Columns.Column>
            <i className="fas fa-file-alt fa-10x has-text-danger is-hidden-touch"></i>
            <i className="fas fa-file-alt fa-3x has-text-danger is-hidden-desktop mb-5"></i>
            <h2 className="is-size-3-touch">Du bewirbst dich</h2>
          </Columns.Column>
        </Columns>

        <Columns vCentered={ true } textAlign={ 'center' } className="mx-0">
          <Columns.Column>
            <ArrowDown height="400px" className="is-hidden-touch"/>
            <i className="is-hidden-desktop fa fa-chevron-down fa-2x my-6 has-text-danger"></i>
          </Columns.Column>
          <Columns.Column/>
          <Columns.Column/>
        </Columns>

        <Columns vCentered={ true } textAlign={ 'center' } className="mx-0">
          <Columns.Column>
            <i className="fas fa-comments fa-10x has-text-danger is-hidden-touch"></i>
            <i className="fas fa-comments fa-3x has-text-danger is-hidden-desktop mb-5"></i>
            <h2 className="is-size-3-touch">Kennenlerngespräch</h2>
          </Columns.Column>

          <Columns.Column>
            <ArrowRight className="is-hidden-touch"/>
            <i className="is-hidden-desktop fa fa-chevron-down fa-2x my-6 has-text-danger"></i>
          </Columns.Column>

          <Columns.Column>
            <i className="fas fa-car-building fa-10x has-text-danger is-hidden-touch"></i>
            <i className="fas fa-car-building fa-3x has-text-danger is-hidden-desktop mb-5"></i>
            <h2 className="is-size-3-touch">Begehung des Arbeitsplatzes beim Kunden</h2>
          </Columns.Column>
        </Columns>

        <Columns vCentered={ true } textAlign={ 'left' } className="is-hidden-touch mx-0">

          <Columns.Column/>
          <Columns.Column>
            <ArrowDiagonal height="200px"/>
          </Columns.Column>
        </Columns>

        <Columns vCentered={ true } textAlign={ 'center' } className="is-hidden-desktop mx-0">
          <Columns.Column/>
          <Columns.Column>
            <i className="fa fa-chevron-down fa-2x my-6 has-text-danger"></i>
          </Columns.Column>
        </Columns>

        <Columns vCentered={ true } textAlign={ 'center' } className="mx-0">
          <Columns.Column>
            <img src={ Einstellung } alt="" width={ '200px' }/>
            <h2 className="is-size-3-touch">Einstellung</h2>
          </Columns.Column>
        </Columns>
      </Container>
    )
  }
}
