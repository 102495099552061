import {Component} from "react";
import TagManager from "react-gtm-module";
import StepWizard from "react-step-wizard";
import "./App.scss";
import {ApplyNow} from "./components/ApplyNow";
import {Footer} from "./components/Footer";
import {Funktion} from "./components/Funktion";
import {Header} from "./components/Header";
import {Step2} from "./components/Step2";
import {Step3} from "./components/Step3";
import {Step4} from "./components/Step4";
import {Step5} from "./components/Step5";
import {Step6} from "./components/Step6";
import {ThankYou} from "./components/ThankYou";
import {Vorteile} from "./components/Vorteile";
import {WirSind} from "./components/WirSind";
import "./transitions.scss";

type WizardProps = any

const tagManagerArgs = {
  gtmId: 'GTM-MPRKPGD'
}

TagManager.initialize(tagManagerArgs);

export type WizardState = {
  form: { [key: string]: string };
  submitDisabled: boolean;
  stepWizard?: any;
  transitions: {
    enterRight: string,
    enterLeft: string,
    exitRight: string,
    exitLeft: string,
    intro: string,
  };
};

export class App extends Component<WizardProps, WizardState> {
  state: WizardState = {
    form: {},
    submitDisabled: false,
    transitions: {
      enterRight: `animated enterRight`,
      enterLeft: `animated enterLeft`,
      exitRight: `animated exitRight`,
      exitLeft: `animated exitLeft`,
      intro: `animated intro`,
    },
  };

  constructor(props: WizardProps) {
    super(props);
    this.setInstance = this.setInstance.bind(this);
    this.onStepChange = this.onStepChange.bind(this);
  }

  onStepChange(stats: any) {
    this.setState({
      form: this.state.form
    });

    window.scrollTo(0, 0);
  }

  setInstance(SW: any) {
    this.setState(currentState => {
      return {stepWizard: SW};
    });
  }

  render() {
    return (
      <div>
        <StepWizard
          onStepChange={this.onStepChange}
          isHashEnabled={true}
          transitions={this.state.transitions} // comment out for default transitions
          instance={this.setInstance}>
          <div>
            <Header state={this.state}/>
            <WirSind/>
            <Vorteile/>
            <Funktion/>
            <ApplyNow/>
          </div>

          <div>
            <Step2 state={this.state}/>
          </div>

          <div>
            <Step3 state={this.state}/>
          </div>

          <div>
            <Step4 state={this.state}/>
          </div>

          <div>
            <Step5 state={this.state}/>
          </div>

          <div>
            <Step6 state={this.state}/>
          </div>

          <div>
            <ThankYou state={this.state}/>
          </div>
        </StepWizard>

        <Footer/>
      </div>
    );
  }
}

export default App;
