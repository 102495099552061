import {ChangeEvent, Component} from "react";
import {Button, Columns, Container, Form} from "react-bulma-components";
import {WizardState} from "../App";
import {InputField} from "./InputField";

export class Step6 extends Component<{ state: WizardState }> {
  constructor(props: any) {
    super(props);
    this.state = {...props};

    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChange = this.onChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  onChangeGender(e: ChangeEvent<HTMLInputElement>) {
    this.props.state.form.salutation = e.target.value;
    this.setState({form: this.props.state.form});
  }

  onChange(e: ChangeEvent<HTMLInputElement>) {
    this.props.state.form[e.target.name] = e.target.value;
    this.setState({form: this.props.state.form});
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getRadio(value: string) {
    return (
      <span>
        <Form.Input className={"is-checkradio"} type={"radio"} name={"radio-gender"}
                    id={`radioGender${this.capitalizeFirstLetter(value)}`}
                    value={value}
                    checked={this.props.state.form.salutation === value}
                    onChange={this.onChangeGender}/>
        <Form.Label htmlFor={`radioGender${this.capitalizeFirstLetter(value)}`}>
          {this.capitalizeFirstLetter(value)}
        </Form.Label>
      </span>
    );
  }

  async nextStep() {
    this.props.state.submitDisabled = true
    this.setState({submitDisabled: true})

    const search = window.location.search;
    const source = new URLSearchParams(search).get("s");
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        c: "run",
        cuq: "VxnT7AopKkoAAEe9A7IAAAAJ",
        juq: "YefTEtUd0wl1ht-snJXfEQAAAE0",
        jh_id: "697984",
        title: "akut.jobs Initiativbewerbung",
        bewerbungsgrund: "Kurzbewerbung",
        jobboard: source || "",
        ...this.props.state.form,
        type: this.props.state.form.jobType,
        message: "Hallo liebes akut.jobs Team,\nanbei die Daten einer neuen Bewerbung auf akut.jobs:\n\n" +
          `Ansprache: ${this.props.state.form.gender}\n` +
          `Vorname: ${this.props.state.form.firstname}\n` +
          `Nachname: ${this.props.state.form.lastname}\n` +
          `Wohnort: ${this.props.state.form.zip} ${this.props.state.form.city}\n` +
          `E-Mail: ${this.props.state.form.email}\n` +
          `Telefon: ${this.props.state.form.phone}\n\n` +
          `Branche: ${this.props.state.form.jobType}\n` +
          `Stelle: ${this.props.state.form.jobPosition}\n` +
          `Anstellung: ${this.props.state.form.workingTime}\n` +
          `Verfügbarkeit: ${this.props.state.form.startingDate}`+
          `Herkunft: ${source || "Direkt"}`
      })
    };

    const response = await fetch("https://us-central1-akut-medizin.cloudfunctions.net/submitApplicationForm", requestOptions);
    const data = await response.json();

    console.log(data);

    this.props.state.submitDisabled = false
    this.setState({form: {}, submitDisabled: false});
    this.props.state.stepWizard.nextStep();
  }

  render() {
    return (
      <div className="header">
        <header>
          <h1 className="my-10">Sende uns Deine Kontaktdaten zu, wir melden uns!</h1>
        </header>

        <Form.Control>
          <Container className="py-6 pb-10">
            <Columns centered={true} breakpoint="desktop" className="mx-0">
              <Columns.Column desktop={{size: 8}}>
                <Columns centered={true} breakpoint="desktop" className="mx-0">
                  <Columns.Column desktop={{size: 12}}>
                    <Form.Field>
                      {this.getRadio("frau")}
                      {this.getRadio("herr")}
                      {this.getRadio("divers")}
                    </Form.Field>
                  </Columns.Column>
                </Columns>

                <Columns centered={true} breakpoint="desktop" className="mx-0">
                  <Columns.Column desktop={{size: 6}} className={"has-text-centered-mobile"}>
                    <InputField label={"Vorname"} placeholder={"Vorname..."}
                                value={this.props.state.form.firstname || ""}
                                name={"firstname"}
                                onChange={this.onChange}/>
                  </Columns.Column>

                  <Columns.Column desktop={{size: 6}} className={"has-text-centered-mobile"}>
                    <InputField label={"Nachname"} placeholder={"Nachname..."}
                                name={"lastname"}
                                onChange={this.onChange}
                                value={this.props.state.form.lastname || ""}/>
                  </Columns.Column>
                </Columns>

                <Columns centered={true} breakpoint="desktop" className="mx-0">
                  <Columns.Column desktop={{size: 6}} className={"has-text-centered-mobile"}>
                    <InputField label={"Stadt"} placeholder={"Stadt"}
                                name={"city"}
                                onChange={this.onChange}
                                value={this.props.state.form.city || ""}/>
                  </Columns.Column>

                  <Columns.Column desktop={{size: 6}} className={"has-text-centered-mobile"}>
                    <InputField label={"Postleitzahl"} placeholder={"PLZ"}
                                name={"zip"}
                                onChange={this.onChange}
                                value={this.props.state.form.zip || ""}/>
                  </Columns.Column>
                </Columns>

                <Columns centered={true} breakpoint="desktop" className="mx-0">
                  <Columns.Column desktop={{size: 6}} className={"has-text-centered-mobile"}>
                    <InputField label={"E-Mail"} type={"email"} placeholder={"E-Mail Adresse"}
                                name={"email"}
                                onChange={this.onChange}
                                value={this.props.state.form.email || ""}/>
                  </Columns.Column>

                  <Columns.Column desktop={{size: 6}} className={"has-text-centered-mobile"}>
                    <InputField label={"Telefon"} type={"tel"} placeholder={"+49 171 1234567"}
                                name={"phone"}
                                onChange={this.onChange}
                                value={this.props.state.form.phone || ""}/>
                  </Columns.Column>
                </Columns>

                <Columns centered={true} breakpoint="desktop" className="mx-0">
                  <Columns.Column desktop={{size: 12}}>
                    <Button color="primary" rounded={true} fullwidth={true} size={"large"} textAlign={"center"}
                            onClick={this.nextStep} disabled={this.props.state.submitDisabled}>
                      Jetzt senden!
                    </Button>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
            </Columns>
          </Container>
        </Form.Control>
      </div>
    );
  }

}
