import { Component } from 'react';
import { WizardState } from '../App';
import { DateIcons } from './DateIcons';

export class Step5 extends Component<{ state: WizardState }> {

  render() {
    return (
      <div className="header">
        <header>
          <h1 className="my-10">Ab wann suchst Du?</h1>
        </header>

        <DateIcons state={ this.props.state }/>

      </div>
    )
  }

}
