import { Component } from 'react';
import { Navbar } from 'react-bulma-components';

export class Footer extends Component {
  render() {
    return (
      <div>
        <div className="footer">
          <footer>
            <Navbar>
              <Navbar.Container>
                <Navbar.Menu className="is-justify-content-center is-flex-grow-1" mobile={ { display: 'flex' } }>
                  <Navbar.Item textAlign={ 'center' } href="https://www.akut-med.de/rechtliches/impressum" target="_blank" rel="noreferrer">
                    <Navbar.Link arrowless={ true }>
                      Impressum
                    </Navbar.Link>
                  </Navbar.Item>
                  <Navbar.Item textAlign={ 'center' } href="https://www.akut-med.de/rechtliches/datenschutz" target="_blank"
                               rel="noreferrer">
                    <Navbar.Link arrowless={ true }>
                      Datenschutz
                    </Navbar.Link>
                  </Navbar.Item>
                  <Navbar.Item textAlign={ 'center' }>
                    <Navbar.Link arrowless={ true }>
                      Kontakt
                    </Navbar.Link>
                  </Navbar.Item>
                </Navbar.Menu>
              </Navbar.Container>
            </Navbar>
          </footer>
        </div>
      </div>
    )
  }
}
