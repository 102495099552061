import {Component} from "react";
import {Button, Columns, Container} from "react-bulma-components";
import {WizardState} from "../App";

export class ThankYou extends Component<{ state: WizardState }> {

  constructor(props: any) {
    super(props);
    this.state = {...props};

    this.nextStep = this.nextStep.bind(this);
  }

  nextStep() {
    this.props.state.stepWizard.goToStep(1);
  }

  render() {
    return (
      <div className="header">
        <header>
          <h1 className="my-10">Vielen Dank, wir melden uns!</h1>
        </header>

        <Container className="ThankYou">
          <Columns vCentered={true} textAlign={"center"} className="mx-0">
            <Columns.Column>
              <Columns centered={true} breakpoint="desktop" className="mx-0">
                <Columns.Column desktop={{size: 12}}>
              <p>
                Herzlichen Dank für Deine Bewerbung und das damit verbundene Interesse an unserem Unternehmen.
              </p>
                </Columns.Column>
              </Columns>

              <Columns centered={true} breakpoint="desktop" className="mx-0">
                <Columns.Column desktop={{size: 12}}>
              <p>
                Bitte habe Verständnis, dass die Bearbeitung Deiner Bewerbung ein wenig Zeit in Anspruch nimmt. Wir
                setzen uns schnellstmöglich mit Dir in Verbindung und bitten Dich bis dahin um etwas Geduld.
              </p>
                </Columns.Column>
              </Columns>

              <Columns centered={true} breakpoint="desktop" className="mx-0">
                <Columns.Column desktop={{size: 12}}>
              <p>
                Solltest Du noch weitere Fragen zu diesem Stellenangebot oder der Online-Bewerbung haben, zögere
                nicht uns zu kontaktieren.
              </p>
                </Columns.Column>
              </Columns>

              <Columns centered={true} breakpoint="desktop" className="mx-0">
                <Columns.Column desktop={{size: 12}}>
              <p>
                Wir beantworten gerne Deine Fragen oder helfen Dir bei Problemen.
              </p>
                </Columns.Column>
              </Columns>

              <Columns centered={true} breakpoint="desktop" className="mx-0">
                <Columns.Column desktop={{size: 12}}>
                  <Button color="primary" rounded={true} fullwidth={true} size={"large"} textAlign={"center"} onClick={this.nextStep}>
                    Zurück zu Startseite
                  </Button>
                </Columns.Column>
              </Columns>

            </Columns.Column>
          </Columns>
        </Container>
      </div>
    );
  }

}
