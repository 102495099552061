import {Component} from "react";
import {Columns, Container} from "react-bulma-components";
import {WizardState} from "../App";
import {ClickableIcon} from "./ClickableIcon";

export class DateIcons extends Component<{ state: WizardState }> {
  nextStep = () => {
    console.log(this.props.state);
    this.props.state.stepWizard.nextStep();
  };

  render() {
    return (
      <Container className="DateIcons py-6 pb-10">
        <Columns centered={true} breakpoint="mobile" className="mx-0">
          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                         field={"startingDate"} value={"Sofort"}
                         size={12}
                         desktop={6}
                         icon={"far fa-calendar-check"} title={"Sofort"}/>

          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                         field={"startingDate"} value={"Innerhalb von 2 Monaten"}
                         icon={"far fa-calendar"} title={"Innerhalb von 2 Monaten"}
                         size={12}
                         desktop={6}
                         additionalIconText={"2"}/>

          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                         field={"startingDate"} value={"Innerhalb von 6 Monaten"}
                         icon={"far fa-calendar"} title={"Innerhalb von 6 Monaten"}
                         size={12}
                         desktop={6}
                         additionalIconText={"6+"}/>

          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                         field={"startingDate"} value={"In 6 Monaten bis 1 Jahr"}
                         icon={"far fa-calendar"} title={"In 6 Monaten bis 1 Jahr"}
                         size={12}
                         desktop={6}
                         additionalIconText={"6+"}/>
        </Columns>
      </Container>
    );
  }

}
