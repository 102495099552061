import {Component} from "react";
import {Columns, Container} from "react-bulma-components";
import {WizardState} from "../App";
import {ClickableIcon} from "./ClickableIcon";

export class TimeIcons extends Component<{ state: WizardState }> {
  constructor(props: { state: WizardState }) {
    super(props);
    this.state = {...props};
  }

  static getDerivedStateFromProps(props: WizardState) {
    if (props.stepWizard) {
      return {stepWizard: props.stepWizard};
    }

    return null;
  }

  render() {
    return (
      <Container className="TimeIcons py-6 pb-10">
        <Columns centered={true} breakpoint="mobile" className="mx-0">
          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                         field={"workingTime"} value={"Vollzeit"} size={12} desktop={12}
                         icon={"fas fa-clock"} title={"Vollzeit"}/>
          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"workingTime"}
                         value={"Teilzeit"} size={12} desktop={12}
                         icon={"far fa-clock"} title={"Teilzeit"}/>
          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"workingTime"}
                         value={"Nebenbeschäftigung"} size={12} desktop={12}
                         icon={"fal fa-clock"} title={"Nebenbeschäftigung"}/>
        </Columns>
      </Container>
    );
  }

}
