import { Component } from 'react';
import { Columns, Container } from 'react-bulma-components';
import WeAre from '../SVG/WeAre.svg';

export class WirSind extends Component {
  render() {
    return (
      <Container className="WeAre">
        <Columns vCentered={ true } className="mx-0">
          <Columns.Column className="py-10">
            <img src={ WeAre } alt=""/>
          </Columns.Column>
        </Columns>
      </Container>
    )
  }
}
