import {Component} from "react";
import {WizardState} from "../App";
import {JobIcons} from "./JobIcons";

export class Step2 extends Component<{ state: WizardState }> {

  render() {
    return (
      <div className="header">
        <header>
          <h1 className="my-10">Welche Stelle interessiert Dich?</h1>
        </header>

        <JobIcons state={this.props.state}/>

      </div>
    );
  }

}
