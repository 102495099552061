import { Component } from 'react';
import { WizardState } from '../App';
import { TimeIcons } from './TimeIcons';

export class Step4 extends Component<{ state: WizardState }> {

  render() {
    return (
      <div className="header">
        <header>
          <h1 className="my-10">Was für eine Stelle möchtest Du?</h1>
        </header>

        <TimeIcons state={ this.props.state }/>

      </div>
    )
  }

}
