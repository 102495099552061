import {Component} from "react";
import {Columns, Container} from "react-bulma-components";
import {WizardState} from "../App";
import {ClickableIcon} from "./ClickableIcon";

export class Icons extends Component<{ state: WizardState }> {
  constructor(props: { state: WizardState }) {
    super(props);
    this.state = {...props};
  }

  static getDerivedStateFromProps(props: WizardState) {
    console.log("GET STATE");

    if (props.stepWizard) {
      return {stepWizard: props.stepWizard};
    }

    return null;
  }

  render() {
    return (
      <Container className="Icons py-6 pb-10">
        <Columns centered={true} breakpoint="mobile" className="mx-0">
          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobType"}
                         value={"Altenpflege"} size={6}
                         icon={"fa-walker"} title={"Altenpflege"}/>
          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobType"}
                         value={"Krankenpflege"} size={6}
                         icon={"fa-stethoscope"} title={"Krankenpflege"}/>
          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobType"}
                         value={"Pädagogik"} size={6}
                         icon={"fa-puzzle-piece"} title={"Pädagogik"}/>
          <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobType"}
                         value={"Weitere"}
                         size={6}
                         icon={"fa-ellipsis-h"} title={"Weitere"}/>
        </Columns>
      </Container>
    );
  }

}
