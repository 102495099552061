import {ChangeEventHandler, Component} from "react";
import {Form} from "react-bulma-components";

const {Input, Field, Control, Label} = Form;

export class InputField extends Component<{ label?: string, name?: string, type?: string, placeholder?: string, className?: string, onChange?: ChangeEventHandler<HTMLInputElement> | undefined, value?: string }> {
  render() {
    return (
      <Field>
        {this.props.label &&
          <Label className={"ml-5 is-large"}>
            {this.props.label}
          </Label>
        }
        <Control>
          <Input
            name={this.props.name?.toLowerCase()}
            placeholder={this.props.placeholder || ""}
            className={`is-rounded ${this.props.className || ""}`}
            size={"large"}
            value={this.props.value}
            type={this.props.type || "text"}
            onChange={this.props.onChange}
          />
        </Control>
      </Field>
    );
  }
}
