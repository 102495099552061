import {Component} from "react";
import {Columns, Container} from "react-bulma-components";
import {WizardState} from "../App";
import {ClickableIcon} from "./ClickableIcon";

export class JobIcons extends Component<{ state: WizardState }> {
  constructor(props: { state: WizardState }) {
    super(props);
    this.state = {...props};
  }

  static getDerivedStateFromProps(props: WizardState) {
    if (props.stepWizard) {
      return {stepWizard: props.stepWizard};
    }

    return null;
  }

  render() {
    return (
      <Container className="Icons py-6 pb-10">
        {this.props.state.form.jobType && this.props.state.form.jobType === "Altenpflege" &&
          <Columns centered={true} breakpoint="mobile" className="mx-0">
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                           field={"jobPosition"} value={"Exam. Altenpfleger (m/w/d)"} size={6}
                           icon={"fa-user"} title={"Exam. Altenpfleger (m/w/d)"} desktop={4}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Pflegefachkraft (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Pflegefachkraft (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Pflegedienstleitung (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Pflegedienstleitung (m/w/d)"}/>
          </Columns>
        }

        {this.props.state.form.jobType && this.props.state.form.jobType === "Krankenpflege" &&
          <Columns centered={true} breakpoint="mobile" className="mx-0">
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                           field={"jobPosition"} value={"Exam. Gesundheits- und Krankenpfleger (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Exam. Gesundheits- und Krankenpfleger (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Krankenpflegehelfer (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Krankenpflegehelfer (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Kinderkrankenpfleger (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Kinderkrankenpfleger (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Operationstechnischer Assistent (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Operationstechnischer Assistent (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Hebamme (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Hebamme (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"GKP mit Fachweiterbildung (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"GKP mit Fachweiterbildung (m/w/d)"}/>
          </Columns>
        }

        {this.props.state.form.jobType && this.props.state.form.jobType === "Pädagogik" &&
          <Columns centered={true} breakpoint="mobile" className="mx-0">
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                           field={"jobPosition"} value={"Erzieher (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Erzieher (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Kinderpfleger (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Kinderpfleger (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Sozialassistent (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Sozialassistent (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                           field={"jobPosition"} value={"Sozialpädagogischer Assistent (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Sozialpädagogischer Assistent (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Heilerziehungspfleger (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Heilerziehungspfleger (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Pädagogen (m/w/d) mit Hochschulabschluss"} size={6} desktop={4}
                           icon={"fa-user"} title={"Pädagogen (m/w/d) mit Hochschulabschluss"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Schulbegleiter (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Schulbegleiter (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Sozialhelfer (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Sozialhelfer (m/w/d)"}/>
          </Columns>
        }

        {(!this.props.state.form.jobType || this.props.state.form.jobType === "Weitere") &&
          <Columns centered={true} breakpoint="mobile" className="mx-0">
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state}
                           field={"jobPosition"} value={"Medizinischer Fachangestellter (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Medizinischer Fachangestellter (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Zahnmedizinischer Fachangestellter (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Zahnmedizinischer Fachangestellter (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Physiotherapeut (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Physiotherapeut (m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Medizinisch-technischer Assistent(m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Medizinisch-technischer Assistent(m/w/d)"}/>
            <ClickableIcon wizard={this.props.state.stepWizard} state={this.props.state} field={"jobPosition"}
                           value={"Sterilisationsassistent (m/w/d)"} size={6} desktop={4}
                           icon={"fa-user"} title={"Sterilisationsassistent (m/w/d)"}/>
          </Columns>
        }
      </Container>
    );
  }

}
